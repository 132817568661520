import { UrlLink } from '@/configs/urlLink';
import _format from './format';

const _navigate = {
  categoriesNews: (slug: string, type: string) => {
    if (type === 'blog')
      return `${UrlLink.blog.category}/${_format.convertTitleToSlug(slug)}`;
    return `${UrlLink.news.category}/${_format.convertTitleToSlug(slug)}`;
  },

  detailNewsByTitleId: (title: string, id: number, type: string) => {
    if (type === 'blog')
      return `${UrlLink.blog.detail}/${_format.convertTitleToSlug(
        title || '',
      )}-${id}`;
    return `${UrlLink.news.news}/${_format.convertTitleToSlug(
      title || '',
    )}-${id}`;
  },
  detailNewsBySlugId: (slug: string, id: number, type: string) => {
    if (type === 'blog') return `${UrlLink.blog.detail}/${slug || ''}-${id}`;
    return `${UrlLink.news.news}/${slug || ''}-${id}`;
  },
  detailInsightBySlugId: (slug: string, id: number) => {
    return `${UrlLink.insight.index}/${slug || ''}-${id}`;
  },
};

export default _navigate;
