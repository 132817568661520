import dayjs from 'dayjs';

const _format = {
  formatDefaultDate: (
    date?: string | number | Date,
    formatString = 'DD/MM/YYYY',
  ): string => {
    return dayjs(date).format(formatString);
  },

  convertTitleToSlug: (title?: string | null) => {
    return title
      ? title
          .toLowerCase()
          .replace(/([-])\s+/g, '') // Loại bỏ khoảng trắng sau dấu "-"
          .replace(/\s+([-])/g, '') // Loại bỏ khoảng trắng trc dấu "-"
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/đ/g, 'd')
          .replace(/Đ/g, 'D')
          .replace(/[?,:%'"“”\b`’‘&+()]/g, '')
          .replace(/[/.]/g, '-')
          .trim()
          .split(' ')
          .join('-')
      : '';
  },

  convertUrlToId: (query?: string) => {
    const lastDashIndex = query?.lastIndexOf('-') ?? 0;
    return query?.substring(lastDashIndex + 1);
  },
  convertUrlToSlug: (query?: string) => {
    const lastDashIndex = query?.lastIndexOf('-') ?? 0;
    return query?.substring(0, lastDashIndex);
  },
  formatDateFull: (
    date: string | number | Date,
    formatString = 'ddd DD/MM/YYYY:HH:mm:ss',
  ): string => {
    return dayjs(date).format(formatString);
  },
  formatDateMagazine: (
    date: string | number | Date,
    formatString = 'MM/YYYY',
  ): string => {
    return dayjs(date).format(formatString);
  },
  formatDateNews: (
    date: string | number | Date,
    formatString = 'DD/MM/YYYY',
  ): string => {
    return dayjs(date).format(formatString);
  },
  convertSecondToMinutes: (seconds: number) => {
    const minutes = Math.floor(seconds / 60); // Lấy phần nguyên số phút
    const remainingSeconds = seconds % 60; // Lấy phần dư số giây
    // return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    if (remainingSeconds < 30) return `${minutes} phút`;
    else return `${minutes + 1} phút`;
  },
  convertScoreRating: (score: number) => {
    const floorValue = Math.floor(score);
    const decimal = score - floorValue;
    if (decimal < 0.25) {
      return floorValue;
    } else if (decimal >= 0.75) {
      return floorValue + 1;
    } else {
      return floorValue + 0.5;
    }
  },
  convertPublicDate: (create_at: Date) => {
    return new Date(create_at).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
    });
  },
};
export default _format;
