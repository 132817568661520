import { Order_By } from '@/generated/graphql';
import { EXCHANGE_LIMIT } from '@/constants/exchange';
import { getCurrentDateRoundedHour } from '@/utils';

// homepage
export const MagazineHomepageParams = {
  limit: 1,
  orderBy: [{ index: Order_By.DescNullsLast }],
};
export const AllCategoryPlaylistParams = {
  orderBy: [{ playlist_order: Order_By.AscNullsLast }],
};
export const AllCategorySeriesParams = {
  orderBy: [{ series_order: Order_By.AscNullsLast }],
};
export const ExchangeExpertHomepageParams = {
  limit: 4,
  orderBy: {
    expert_score: Order_By.DescNullsLast,
  },
};
export const ExchangeUserHomepageParams = {
  limit: 4,
  orderBy: {
    user_score: Order_By.DescNullsLast,
  },
};
export const NewsHomepageParams = {
  limitNews: 4,
  orderByNews: [
    {
      create_at: Order_By.DescNullsLast,
    },
  ],
  whereNews: {
    date: {
      _lte: getCurrentDateRoundedHour(),
    },
    is_public: {
      _eq: true,
    },
  },
};
export const OtherInsightHomepageParams = {
  offset: 0,
  orderBy: {
    public_at: Order_By.DescNullsFirst,
  },
};
export const NewsLastestParams = {
  limit: 5,
  orderBy: [{ date: Order_By.DescNullsLast }],
  where: {
    date: {
      _lte: getCurrentDateRoundedHour(),
    },
    is_public: {
      _eq: true,
    },
  },
};

export const NewsPinParams = {
  limit: 6,
  where: {
    is_public: {
      _eq: true,
    },
    date: {
      _lte: getCurrentDateRoundedHour(),
    },
    isHot: {
      _eq: true,
    },
  },
};

export const NewsBlogPageParams = {
  limit: 3,
  orderBy: {},
};
export const KnowledgeHomepageParams = {
  limit: 4,
  orderBy: [
    {
      is_feature: Order_By.DescNullsLast,
      updated_at: Order_By.DescNullsLast,
      order: Order_By.Asc,
    },
  ],
};
export const KnowledgePageParams = {
  limit: 8,
};

export const NewsPinnedParams = {
  limit: 3,
  orderBy: [{ date: Order_By.Desc }],
};

export const ExchangeListingParams = {
  limit: EXCHANGE_LIMIT,
  orderBy: [
    {
      updated_at: Order_By.DescNullsLast,
    },
  ],
  where: {
    name: {
      _ilike: `%%`,
    },
  },
  offset: 0,
};
