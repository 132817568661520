import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {
  Order_By,
  useGetAllInsightsByMagazineIdQuery,
  useGetAllMagazineQuery,
} from '@/generated/graphql';
import { MagazineHomepageParams } from '@/constants/defaultParams';
import { UrlLink } from '@/configs/urlLink';
import _navigate from '@/utils/navigateLink';
import { useBlockContext } from '@/context/useBlockContext';
import { LockedCategoryIcon } from '@/assets/icons';
import InfomationModal from '../shared/InfomationModal';

const InsightsHomepageSection = () => {
  const { block } = useBlockContext() as IBlockContext;
  const { data } = useGetAllMagazineQuery({
    variables: MagazineHomepageParams,
  });

  const { data: DataAllInsightsMagazine } = useGetAllInsightsByMagazineIdQuery({
    variables: {
      orderBy: {
        insight_order: Order_By.AscNullsLast,
      },
      where: {
        magazine_id: {
          _eq: data?.magazine?.[0]?.id || 0,
        },
      },
    },
  });

  const isOpenInsight = useCallback(
    (is_block_video: boolean) => {
      if (block.is === false) {
        return true;
      } else {
        if (!is_block_video) {
          return true;
        } else {
          return false;
        }
      }
    },
    [block.is],
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [titleUnlock, setTitleUnlock] = useState('');
  const handleClickUnlock = (isOpen: boolean, title: string) => {
    if (!isOpen) {
      setTitleUnlock(title);
      setIsOpenModal(true);
    }
  };

  // const firstFiveItems = DataAllInsightsMagazine?.insight.slice(0, 5);
  const { thumbnail, title } = data?.magazine?.[0] || {};

  return (
    <div className="py-8 md:pb-0 lg:py-16">
      {isOpenModal && (
        <InfomationModal setShowModel={setIsOpenModal} title={titleUnlock} />
      )}
      <div className="container">
        <div className="hidden grid-cols-2 justify-center md:gap-16 lg:grid">
          <div className="w-full">
            <div className="py-8">
              <h1 className="text-mobile-h2 text-neutral-dark font-poppins uppercase lg:text-[56px] lg:font-semibold lg:leading-[60.48px]">
                Trading Insights
              </h1>
              <h1 className="text-mobile-h3 md:text-h3 text-neutral-background-dark font-header uppercase lg:text-5xl lg:font-normal">
                {title}
              </h1>
            </div>
            <div className="flex flex-col gap-[7px]">
              {DataAllInsightsMagazine?.insight
                .slice(0, 5)
                ?.map((item: any, index: number) => {
                  const isOpen = isOpenInsight(item?.is_block);
                  const urlLink = `${process.env.NEXT_PUBLIC_DOMAIN}${
                    item?.slug
                      ? _navigate.detailInsightBySlugId(item?.slug, item?.id)
                      : _navigate.detailInsightBySlugId(item?.title, item?.id)
                  }`;
                  return (
                    <div
                      key={index}
                      className="text-mobile-body1 flex items-center justify-between gap-5 border-b-[0.5px] py-3 font-sans md:text-lg md:font-medium md:leading-7"
                    >
                      {isOpen ? (
                        <Link
                          href={
                            item?.slug
                              ? _navigate.detailInsightBySlugId(
                                  item?.slug,
                                  item?.id,
                                )
                              : _navigate.detailInsightBySlugId(
                                  item?.title,
                                  item?.id,
                                )
                          }
                        >
                          <p className="text-PC-Title-Medium hover:text-primary-eastern font-sans md:text-lg md:font-medium md:leading-7 lg:line-clamp-2">
                            {item.title}
                          </p>
                        </Link>
                      ) : (
                        <div
                          className="relative w-full cursor-not-allowed"
                          onClick={() => handleClickUnlock(isOpen, urlLink)}
                        >
                          <div className="absolute -right-0.5 -top-1">
                            <LockedCategoryIcon />
                          </div>
                          <p className="text-PC-Title-Medium font-sans opacity-50 md:text-lg md:font-medium md:leading-7 lg:line-clamp-2">
                            {item.title}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="mt-2 flex items-center gap-x-2 pt-[22px] md:gap-x-11 lg:mt-auto">
              <Link
                className="text-mobile-btn md:btn bg-primary-easternDark rounded-[4px] px-8 py-4 text-white shadow-[0_0_15px_0_rgba(94,162,180,0.35)] duration-500 hover:scale-105 active:scale-95"
                href={`${UrlLink.insight.index}/${data?.magazine?.[0]?.slug}`}
              >
                Đọc ngay
              </Link>
            </div>
          </div>
          <div className="relative lg:h-[667px] lg:w-[471px]">
            <Image
              src={thumbnail || ''}
              alt="Thumbnail magazine"
              fill
              sizes="(max-width: 768px) 100vw, 15vw"
              className="object-cover"
              priority
            />
          </div>
        </div>
        <div className="flex justify-center lg:hidden">
          <div className="">
            <div className="bg-neutral-background-light flex justify-between gap-8 py-4">
              <div className="py-6">
                <h1 className="text-neutral-dark font-poppins text-[32px] font-bold uppercase leading-[44.8px] md:text-[56px] md:font-semibold md:leading-[60.48px]">
                  Trading <br /> Insights
                </h1>
                <h1 className="text-PC-Body-Large text-neutral-background-dark md:font-header font-sans uppercase md:text-[48px] md:font-medium md:leading-[58.51px]">
                  {title}
                </h1>
              </div>
              <div className="flex">
                <div className="relative h-[164px] w-[119px] md:h-[248px] md:w-[176px] lg:h-[667px] lg:w-[471px]">
                  <Image
                    src={thumbnail || ''}
                    alt="Thumbnail magazine"
                    fill
                    sizes="(max-width: 768px) 30vw, 30vw"
                    className="object-cover"
                    priority={true}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              <div className="flex flex-col gap-[7px]">
                {DataAllInsightsMagazine?.insight
                  .slice(0, 5)
                  ?.map((item: any, index: number) => {
                    const isOpen = isOpenInsight(item?.is_block);
                    const urlLink = `${process.env.NEXT_PUBLIC_DOMAIN}${
                      item?.slug
                        ? _navigate.detailInsightBySlugId(item?.slug, item?.id)
                        : _navigate.detailInsightBySlugId(item?.title, item?.id)
                    }`;
                    return (
                      <div
                        key={index}
                        className="text-mobile-body1 flex items-center justify-between gap-5 border-b-[0.5px] py-3 font-sans md:text-lg md:font-medium md:leading-7"
                      >
                        {isOpen ? (
                          <Link
                            href={
                              item?.slug
                                ? _navigate.detailInsightBySlugId(
                                    item?.slug,
                                    item?.id,
                                  )
                                : _navigate.detailInsightBySlugId(
                                    item?.title,
                                    item?.id,
                                  )
                            }
                          >
                            <p className="text-PC-Title-Medium hover:text-primary-eastern font-sans md:text-lg md:font-medium md:leading-7 lg:line-clamp-2">
                              {item.title}
                            </p>
                          </Link>
                        ) : (
                          <div
                            className="relative w-full cursor-not-allowed"
                            onClick={() => handleClickUnlock(isOpen, urlLink)}
                          >
                            <div className="absolute -right-0.5 -top-1">
                              <LockedCategoryIcon />
                            </div>
                            <p className="text-PC-Title-Medium hover:text-primary-eastern font-sans opacity-50 md:text-lg md:font-medium md:leading-7 lg:line-clamp-2">
                              {item.title}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="mt-2 flex items-center gap-x-2 pt-[22px] md:gap-x-11 lg:mt-auto">
                <Link
                  className="text-mobile-btn md:btn bg-primary-easternDark w-full rounded-[4px] px-8 py-4 text-center text-white shadow-[0_0_15px_0_rgba(94,162,180,0.35)] duration-500 hover:scale-105 active:scale-95 md:w-[161px]"
                  href={`${UrlLink.insight.index}/${data?.magazine?.[0]?.slug}`}
                >
                  Đọc ngay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightsHomepageSection;
